import React,{useEffect} from 'react'
import { doc, onSnapshot } from "firebase/firestore";
import { db } from '../../APi/index';
import { useParams } from 'react-router-dom';
import { HiDownload } from 'react-icons/hi';
import jsPDF from "jspdf";
import "jspdf-autotable";
import './single.scss'
import { UserAuth } from '../../Context/AuthContext';
import useAutoLogout from '../../Components/Timeout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useFetchUserRole from '../../Components/checkUserRole';
import { useDispatch, useSelector } from 'react-redux';
import { csrfProtectedPost } from '../../Components/CsrfUtils';

const IndividualUser = () => {

  const dispatch = useDispatch();
  const { user } = UserAuth();
  const userRole = useFetchUserRole(user);
const data = useSelector(state => state.data);
const editData = useSelector(state => state.editData);
const editingKey = useSelector(state => state.editingKey);

const handleInputChange = (event) => {
  dispatch({ type: 'SET_EDIT_DATA', data: { ...editData, [event.target.name]: event.target.value } });
};

const serverURL = process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';

const handleFormSubmit = async (event, key) => {
  event.preventDefault();

  // Update the UI immediately
  dispatch({ type: 'SET_DATA', data: editData });
  dispatch({ type: 'SET_EDITING_KEY', key: null });

  try {
    // Directly pass the data to the csrfProtectedPost function
    const response = await csrfProtectedPost(`${serverURL}/edit-individual-form/${data.id}`, {
      [key]: editData[key]
    });

    const result = response.data;

    if (response.status !== 200) {
      console.error(result.error);
      // If the server returns an error, revert the changes in the UI
      dispatch({ type: 'SET_EDIT_DATA', data });
      toast.error('Update failed. Please try again.');
    } else {
      toast.success('Form updated successfully.');
    }
  } catch (err) {
    console.error('Error:', err);
    // If the request fails, revert the changes in the UI
    dispatch({ type: 'SET_EDIT_DATA', data });
    toast.error('Update failed. Please try again.');
  }
};


const handleEditClick = (key) => {
  dispatch({ type: 'SET_EDITING_KEY', key });
};

const handleCancelClick = () => {
  dispatch({ type: 'SET_EDITING_KEY', key: null });
  dispatch({ type: 'SET_EDIT_DATA', data });
};

    const { logout } = UserAuth(); // Replace UserAuth with your authentication context

    // Use the custom hookfor the automatic logout
    useAutoLogout({
      timeoutDuration: 10 * 60 * 1000 ,//(adjust as needed)
      logout, // Use the logout function from your context
      redirectPath: '/signin', // Specify the redirect path
    });
  

    const {id} = useParams();

    const style={
        size:30,
        marginLeft:10,
        color:'white'
    }

    useEffect(() => {
      const docRef = doc(db, 'individual-kyc', id);
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
          dispatch({ type: 'SET_DATA', data: { ...snapshot.data(), id: snapshot.id } });
      });
  
      // Return a cleanup function to unsubscribe the listener when the component unmounts
      return () => unsubscribe();
    }, [id, dispatch]);

      
    const downloadPDF = () => {
        const doc = new jsPDF('p', 'pt', 'a4');
      
        // Add header
        doc.setFontSize(24);
        doc.setTextColor(128, 0, 32);
        doc.text('NEM Insurance Individual KYC Form', 50, 70);
      
        // Add section 1 - Company Information
        doc.setFontSize(18);
        doc.setTextColor(0, 0, 0);

        doc.setFontSize(16);
        doc.text('Personal Information', 60, 120);
      
        const companyTableColumn = ['Personal Information', ''];
        const companyTableRows = [
            ['Title', data.title],
            ['First Name', data.firstName],
            ['Last Name', data.lastName],
            ['Contact Address', data.contactAddress],
            ['Gender', data.gender],
            ['Nationality', data.nationality],
            ['Country', data.country],
            ['Date of Birth', data.dateOfBirth],
            ['Place of Birth', data.placeOfBirth],
            ['Occupation', data.occupation],
            ['Position', data.position],
            ['Premium Payment Source', data.premiumPaymentSource],
            ['GSM Number', data.GSMno],
            ['Residential Address', data.residentialAddress],
            ['Email Address', data.emailAddress],
            ['Tax ID Number', data.taxIDNumber],
            ['BVN Number', data.BVNNumber],
            ['Identification Type', data.identificationType],
            ['Identification Number', data.identificationNumber],
            ['Issuing Country', data.issuingCountry],
            ['Issued Date', data.issuedDate],
            ['Expiry Date', data.expiryDate],

            ['Business Type', data.businessType],
            ['Employer\'s Name', data.employersName],
            ['Employer\'s Address', data.employersAddress],
            ['Employer\'s Telephone Number', data.employersTelephoneNumber],
            ['Employer\'s Email', data.employersEmail],
            ['Annual Income Range', data.annualIncomeRange],
          ];
      
        const companyTableProps = {
          startY: 140,
          styles: {
            halign: 'left',
            valign: 'middle',
            fontSize: 12,
            cellPadding: 8,
            overflow: 'linebreak',
            lineWidth: 0.1,
          },
          columnStyles: {
            0: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
              fontStyle: 'bold',
            },
            1: {
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
          },
        };
      
        doc.autoTable(companyTableColumn, companyTableRows, companyTableProps);
      
          // Add privacy declarations
      doc.setFontSize(14);
      // doc.setFontStyle('bold' , doc.internal.pageSize.getWidth() / 2, 150, { align: 'center' })
      doc.text('Declaration:', 50, doc.autoTable.previous.finalY + 40);
  
      let yPosition = doc.autoTable.previous.finalY + 80; // Increase space after the header
  
      const declarations = [
          {
              text: `I/We ${data.signature} hereby affirm that all the information provided in this Form/Document is true , accurate and complete to the best of my knowledge.`,
              signature: data.signature
          },
         
      ];
  
      declarations.forEach((declaration, index) => {
        const lines = doc.splitTextToSize(declaration.text, 500); // Adjust the width as needed
        doc.text(lines, 50, yPosition);
        // const textWidth = doc.getTextWidth(declaration.signature);
        // doc.line(80, yPosition + 5, 50 + textWidth, yPosition + 5); // Underline the signature
        yPosition += 24 * lines.length; // Adjust this value as needed to space out the declarations
    });
    
    // Add date under the declarations
    const dateText = `Date: ${new Date().toLocaleDateString()}`;
    doc.text(dateText, 50, yPosition + 20);
    // const dateWidth = doc.getTextWidth(dateText);
    // doc.line(90, yPosition + 30, 50 + dateWidth, yPosition + 30); // Underline the date

       
// Save the PDF
doc.save('KYC Form.pdf');
    }


  return (
  <div className='singles'>
    <div className='form-content'>
    <div className='form-contents'>
    <div className='flex-content'>
  <ul>
    <h1 className='content-h1'>Personal Information</h1>
    {[
      { label: 'Title', key: 'title' },
      { label: 'First Name', key: 'firstName' },
      { label: 'Last Name', key: 'lastName' },
      { label: 'Contact Address', key: 'contactAddress' },
      { label: 'Gender', key: 'gender' },
      { label: 'Nationality', key: 'nationality' },
      { label: 'Country', key: 'country' },
      { label: 'Date of Birth', key: 'dateOfBirth' },
      { label: 'Place of Birth', key: 'placeOfBirth' },
      { label: 'Occupation', key: 'occupation' },
      { label: 'Position', key: 'position' },
      { label: 'Premium Payment Source', key: 'premiumPaymentSource' },
      { label: 'Mobile Number', key: 'GSMno' },
      { label: 'Residential Address', key: 'residentialAddress' },
      { label: 'Email Address', key: 'emailAddress' },
    ].map(({ label, key }) => (
      <li className='form-list' key={key}>
        <p>{label}</p>
        {editingKey === key ? (
          <form onSubmit={(event) => handleFormSubmit(event, key)}>
            <input
              type='text'
              name={key}
              value={editData[key]}
              onChange={handleInputChange}
              className='edit-input'
            />
            <button type='submit' className='edit-submit'>Save</button>
            <button type='button' onClick={handleCancelClick} className='edit-cancel'>
              Cancel
            </button>
          </form>
        ) : (
          <>
            <p className='info'>{data[key]}</p>
            {userRole === 'admin' && (
              <button onClick={() => handleEditClick(key)} className='edit-button'>Edit</button>
            )}
          </>
        )}
      </li>
    ))}
  </ul>
  <ul>
    {[
      { label: 'Identification Number', key: 'identificationNumber' },
      { label: 'BVN Number', key: 'BVNNumber' },
      { label: 'Identification Type', key: 'identificationType' },
      { label: 'Issuing Country', key: 'issuingCountry' },
      { label: 'Issued Date', key: 'issuedDate' },
      { label: 'Expiry Date', key: 'expiryDate' },
      { label: 'Employer\'s Name', key: 'employersName' },
      { label: 'Employer\'s Address', key: 'employersAddress' },
      { label: 'Employer\'s Telephone Number', key: 'employersTelephoneNumber' },
      { label: 'Employer\'s Email', key: 'employersEmail' },
      { label: 'Business Type', key: 'businessType' },
      { label: 'Annual Income Range', key: 'annualIncomeRange' },
    ].map(({ label, key }) => (
      <li className='form-list' key={key}>
        <p>{label}</p>
        {editingKey === key ? (
          <form onSubmit={(event) => handleFormSubmit(event, key)}>
            <input
              type='text'
              name={key}
              value={editData[key]}
              onChange={handleInputChange}
              className='edit-input'
            />
            <button type='submit' className='edit-submit'>Save</button>
            <button type='button' onClick={handleCancelClick} className='edit-cancel'>
              Cancel
            </button>
          </form>
        ) : (
          <>
            <p className='info'>{data[key]}</p>
            {userRole === 'admin' && (
              <button onClick={() => handleEditClick(key)} className='edit-button'>Edit</button>
            )}
          </>
        )}
      </li>
    ))}
  </ul>
</div>
</div>

      <div className='form-contents'>
        <div className='flex-content'>
          <ul>
            <h1>Documents</h1>
            <li className='form-list'>
              <p>Signature</p>
              {data.signature ? (
                <a href={data.signature} target='__blank'>
                  <button className='form-button'>
                    Download Signature <HiDownload style={style} />
                  </button>
                </a>
              ) : (
                <p className='info'>Signature not available</p>
              )}
            </li>
            <li className='form-list'>
              <p>Identification</p>
              {data.identification ? (
                <a href={data.identification} target='__blank'>
                  <button className='form-button'>
                    Download Identification <HiDownload style={style} />
                  </button>
                </a>
              ) : (
                <p className='info'>Identification not available</p>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className='file-download'>
    <button className='form-button' onClick={downloadPDF}>Download Form <HiDownload style={style} /> </button>
    </div>
   
    </div>
    
  )
}

export default IndividualUser